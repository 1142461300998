/*
 * 업무구분: 고객
 * 화 면 명: MSPCM014P
 * 화면설명: 추가정보입력(주소 입력: 직장 또는 집)
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.06.20
 * 작 성 자: 이태훈
 * 2차 작성자: sh.park2022
 */
<template>
  <ur-page-container title="추가정보입력" class="msp" :show-title="true" type="subpage" action-type="none">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="add-box">
          
          <span class="add-title">고객님의 주소를 입력해주세요</span>
          <mo-list-item ref="expItem1" class="ns-radio-accodian">
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <div class="ns-radio-list">
                  <mo-radio v-model="addrType" value="11" @input="fn_CheckSelectedItem('11')">집</mo-radio>
                </div>
              </div>
            </div>
            <template v-slot:expand>
              <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="gray-box">
                <ur-box-container class="relative">
                  <mo-text-field v-model="homeAddr" class="full mt10" readonly searchable underline placeholder="지번, 도로명 검색"
                                  @click-icon="fn_OpenMSPCM130P('11')" @click="fn_OpenMSPCM130P('11')"/>
                  <button class="btn_clear--ty2" v-if="!$bizUtil.isEmpty(homeAddr)">
                    <mo-icon icon="close" @click="fn_ClearAddr('11')"></mo-icon>
                  </button>
                </ur-box-container>
                <mo-text-field v-model="homeDtlad" v-if="!isHideHomeDtladYn" readonly underline placeholder="상세주소를 입력하세요" @click="fn_OpenMSPCM130P('11')"/>
              </ur-box-container>
            </template>
          </mo-list-item>
          <mo-list-item ref="expItem2" class="ns-radio-accodian">
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <div class="ns-radio-list">
                  <mo-radio v-model="addrType" value="12" @input="fn_CheckSelectedItem('12')">회사</mo-radio>
                </div>
              </div>
            </div>
            <template v-slot:expand>
              <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="gray-box">
                <mo-text-field v-model="coAddr" class="full mt10" readonly searchable underline placeholder="지번, 도로명 검색"
                              @click-icon="fn_OpenMSPCM130P('12')" @click="fn_OpenMSPCM130P('12')"/>
                <ur-box-container class="relative">

                  <button class="btn_clear--ty2" v-if="!$bizUtil.isEmpty(coAddr)">
                    <mo-icon icon="close" @click="fn_ClearAddr('12')"></mo-icon>
                  </button>
                </ur-box-container>
                <mo-text-field v-model="coDtlad" v-if="!isHideCoDtladYn" readonly underline placeholder="상세주소를 입력하세요" @click="fn_OpenMSPCM130P('12')"/>

              </ur-box-container>
            </template>
          </mo-list-item>
          <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CloseBackCnfrmPop('Cancel')">취소</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CloseBackCnfrmPop('Confirm')">확인</mo-button>
            </div>
          </ur-box-container>

        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
import MSPCM130P from '@/ui/cm/MSPCM130P' // 주소 검색
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert.vue'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM014P',
  // 현재 화면 ID
  screenId: 'MSPCM014P',
  // 컴포넌트 선언
  components: {
    MspBottomSelect,
    MSPCM130P
  },
  props: {
    pCustCardVO: Object, // 고객 Obj
    pAddrType: String  // 고객 주소타입(집, 회사)
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      title: '추가정보 입력', // 화면명
      tmpCustCardVO: {},
      homeAddr:'',  //집 주소
      homeDtlad:'', //집 상세주소
      coAddr:'',  //회사 주소
      coDtlad:'', //회사 상세주소
      addrScCd: '',  //도로명주소 구분
      
      //팝업 열기
      popup130 : {}, //주소 검색

      isHideHomeDtladYn: true, //집주소 상세 숨김처리 (false: 보임, true: 숨김)
      isHideCoDtladYn: true, //회사주소 상세 숨김처리 (false: 보임, true: 숨김)

      /* 공통코드 */
      addrType: '',
      addrTypes: [
        {
          key: '11',
          label: '자택'
        },
        {
          key: '12',
          label: '직장'
        }
      ],
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {

  },
  
  /** watch 정의 영역 */
  watch: {
    addrType(val) {
      if(val === '11') {
        this.$refs.expItem1.expand()
        this.$refs.expItem2.collapse()      
      } else if (val === '12') {
        this.$refs.expItem1.collapse()
        this.$refs.expItem2.expand()
      }
    },
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    //window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked) // listen to Event

    if(typeof this.pAddrType !== 'undefined' && this.pAddrType !== ''){
      this.addrType = this.pAddrType

      if(this.pCustCardVO !== null && typeof this.pCustCardVO !== 'undefined'){
        // 주소검색팝업에서 (자택, 주소) 선택만 수정에서 둘다 저장 될 수 있도록 수정 2022-07-26
        //초기화
        this.isHideHomeDtladYn = true
        this.isHideCoDtladYn = true

        this.homeAddr = ''  //집 주소
        this.homeDtlad='' //집 상세주소
        this.tmpCustCardVO.homAddrScCd = ''
        this.tmpCustCardVO.homAddr = ''
        this.tmpCustCardVO.homDtlad = ''
        this.tmpCustCardVO.bldgNo01 = ''
        this.tmpCustCardVO.adtnAddr01 = ''
        this.tmpCustCardVO.homFrnoPstcd = ''
        this.tmpCustCardVO.homAddrRefnCd = ''
        this.tmpCustCardVO.homAddrStatCd = ''
        
        this.coAddr=''  //회사 주소
        this.coDtlad='' //회사 상세주소
        this.tmpCustCardVO.jobpAddrScCd = ''
        this.tmpCustCardVO.jobpAddr = ''
        this.tmpCustCardVO.jobpDtlad = ''
        this.tmpCustCardVO.bldgNo02 = ''
        this.tmpCustCardVO.adtnAddr02 = ''
        this.tmpCustCardVO.jobpFrnoPstcd = ''
        this.tmpCustCardVO.jobpAddrRefnCd = ''
        this.tmpCustCardVO.jobpAddrStatCd = ''
        
        if( !this.$bizUtil.isEmpty(this.pCustCardVO.homAddr)){
          this.tmpCustCardVO.homAddr = this.pCustCardVO.homAddr // '서울 동작구 상도로55길'
          this.tmpCustCardVO.homDtlad = this.pCustCardVO.homDtlad // '201동 201호'
          this.tmpCustCardVO.bldgNo01 = this.pCustCardVO.bldgNo01 // '47'
          this.tmpCustCardVO.adtnAddr01 = this.pCustCardVO.adtnAddr01 // '(상도동,래미안상도2차아파트)'
          this.tmpCustCardVO.homFrnoPstcd = this.pCustCardVO.homFrnoPstcd // '06978'
          this.tmpCustCardVO.homAddrRefnCd = this.pCustCardVO.homAddrRefnCd // 직장주소정제코드(01: 정제주소, 02:입력주소)
          this.tmpCustCardVO.homAddrStatCd = '11' // 집주소상태코드 (11:정상, 12:오류, 13:반송(생명특화))

          if (this.pCustCardVO.homAddrScCd === '2') { // 집 도로명 주소
            this.tmpCustCardVO.homAddrScCd = '2'
            this.homeAddr = this.pCustCardVO.homAddr + this.pCustCardVO.bldgNo01  //집 도로명 주소
            this.homeDtlad =  this.pCustCardVO.homDtlad + this.pCustCardVO.adtnAddr01 //집 도로명 상세주소
          }else{ //집 구주소
              this.tmpCustCardVO.homAddrScCd = '1'
              this.homeAddr = this.pCustCardVO.homAddr
              this.homeDtlad = this.pCustCardVO.homDtlad + this.pCustCardVO.bldgNo01
          }
          if(this.homeDtlad !== '' && typeof this.homeDtlad !== 'undefined'){
            this.isHideHomeDtladYn = false
          }
        }

        if( !this.$bizUtil.isEmpty(this.pCustCardVO.jobpAddr)){
          this.tmpCustCardVO.jobpAddr = this.pCustCardVO.jobpAddr // '서울 동작구 상도로55길'
          this.tmpCustCardVO.jobpDtlad = this.pCustCardVO.jobpDtlad // '201동 201호'
          this.tmpCustCardVO.bldgNo02 = this.pCustCardVO.bldgNo02 // '47'
          this.tmpCustCardVO.adtnAddr02 = this.pCustCardVO.adtnAddr02 // '(상도동,래미안상도2차아파트)'
          this.tmpCustCardVO.jobpFrnoPstcd = this.pCustCardVO.jobpFrnoPstcd // 우편번호 '06978'
          this.tmpCustCardVO.jobpAddrRefnCd = this.pCustCardVO.jobpAddrRefnCd // '01' // 직장주소정제코드(01: 정제주소, 02:입력주소)
          this.tmpCustCardVO.jobpAddrStatCd = '11' // 직장주소상태코드 (11:정상, 12:오류, 13:반송(생명특화))

          if (this.pCustCardVO.jobpAddrScCd === '2') { // 도로명 주소
            this.tmpCustCardVO.jobpAddrScCd = '2'
            this.coAddr = this.pCustCardVO.jobpAddr + this.pCustCardVO.bldgNo02
            this.coDtlad = this.pCustCardVO.jobpDtlad + this.pCustCardVO.adtnAddr02
          } else {
            this.tmpCustCardVO.jobpAddrScCd = '1'
            this.coAddr = this.pCustCardVO.jobpAddr
            this.coDtlad = this.pCustCardVO.jobpDtlad + this.pCustCardVO.bldgNo02
          }
          if(this.coDtlad !== '' && typeof this.coDtlad !== 'undefined'){
            this.isHideCoDtladYn = false
          }
        }
      }
    }
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    this.$bizUtil.insSrnLog('MSPCM014P')
  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    //window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_BackBtnClicked) // stop listenning Event
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /*********************************************************
     * Function명: fn_OpenMSPCM130P
     * 설명 : 주소검색 팝업 열기
     *
     *********************************************************/
    fn_OpenMSPCM130P (param){
      let lv_Vm = this
      
      lv_Vm.popup130 = lv_Vm.$bottomModal.open(MSPCM130P, {
        properties: {
        },
        listeners: {
          onPopupClose: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.popup130)
           
            this.fn_ConfirmMSPCM130P(pData)
           
          }
        }
      })
    },
    /*********************************************************
     * Function명: fn_ConfirmMSPCM130P
     * 설명 : 주소 검색 팝업창 호출 결과
     *
     *********************************************************/
    fn_ConfirmMSPCM130P (item){
      this.addrScCd = item.addrScCd
      
      if (this.addrType === '11') { // 자택
        this.homeAddr = ''  //집 주소
        this.homeDtlad='' //집 상세주소
        this.tmpCustCardVO.homAddrScCd = ''
        this.tmpCustCardVO.homAddr = ''
        this.tmpCustCardVO.homDtlad = ''
        this.tmpCustCardVO.bldgNo01 = ''
        this.tmpCustCardVO.adtnAddr01 = ''
        this.tmpCustCardVO.homFrnoPstcd = ''
        this.tmpCustCardVO.homAddrRefnCd = ''
        this.tmpCustCardVO.homAddrStatCd = ''

        if (this.addrScCd === '2') { // 도로명 주소
          this.tmpCustCardVO.homAddrScCd = '2'
          this.tmpCustCardVO.homAddr = item.basAddr // '서울 동작구 상도로55길'
          this.tmpCustCardVO.homDtlad = item.roadNmDtlad // '201동 201호'
          this.tmpCustCardVO.bldgNo01 = item.bldgBonbHo // '47'
          this.tmpCustCardVO.adtnAddr01 = item.roadNmAddmAddr // '(상도동,래미안상도2차아파트)'
          
          this.homeAddr = this.tmpCustCardVO.homAddr + this.tmpCustCardVO.bldgNo01 
          this.homeDtlad =  this.tmpCustCardVO.homDtlad + this.tmpCustCardVO.adtnAddr01
        } else {
          this.tmpCustCardVO.homAddrScCd = '1'
          this.tmpCustCardVO.homAddr = item.basAddr // '서울 동작구 상도동'
          this.tmpCustCardVO.homDtlad = item.dtladAddr // '421번지 래미안상도2차아파트 201동 201호'
          this.tmpCustCardVO.bldgNo01 = item.bldgBonbHo // ''

          this.homeAddr = this.tmpCustCardVO.homAddr
          this.homeDtlad =  this.tmpCustCardVO.homDtlad + this.tmpCustCardVO.bldgNo01
        }

        this.tmpCustCardVO.homFrnoPstcd = item.pstCd // '06978'
        this.tmpCustCardVO.homAddrRefnCd = item.addrRefnCd // 직장주소정제코드(01: 정제주소, 02:입력주소)
        this.tmpCustCardVO.homAddrStatCd = '11' // 집주소상태코드 (11:정상, 12:오류, 13:반송(생명특화))
        this.isHideHomeDtladYn = false

        // 아코디언 펼침 이슈로 닫열 추가
        this.$refs.expItem1.collapse()
        this.$nextTick(() => {
          this.$refs.expItem1.expand()
        })
      } else { // 직장
          this.coAddr=''  //회사 주소
          this.coDtlad='' //회사 상세주소
          this.tmpCustCardVO.jobpDtlad = ''
          this.tmpCustCardVO.bldgNo02 = ''
          this.tmpCustCardVO.adtnAddr02 = ''
          this.tmpCustCardVO.jobpFrnoPstcd = ''
          this.tmpCustCardVO.jobpAddrRefnCd = ''
          this.tmpCustCardVO.jobpAddrStatCd = ''
        if (this.addrScCd === '2') { // 도로명 주소
          this.tmpCustCardVO.jobpAddrScCd = '2'
          this.tmpCustCardVO.jobpAddr = item.basAddr // '서울 동작구 상도로55길'
          this.tmpCustCardVO.jobpDtlad = item.roadNmDtlad // '201동 201호'
          this.tmpCustCardVO.bldgNo02 = item.bldgBonbHo // '47'
          this.tmpCustCardVO.adtnAddr02 = item.roadNmAddmAddr // '(상도동,래미안상도2차아파트)'

          this.coAddr = this.tmpCustCardVO.jobpAddr + this.tmpCustCardVO.bldgNo02
          this.coDtlad = this.tmpCustCardVO.jobpDtlad + this.tmpCustCardVO.adtnAddr02
        } else {
          this.tmpCustCardVO.jobpAddrScCd = '1'
          this.tmpCustCardVO.jobpAddr = item.basAddr // '서울 동작구 상도동'
          this.tmpCustCardVO.jobpDtlad = item.dtladAddr // '421번지 래미안상도2차아파트 201동 201호'
          this.tmpCustCardVO.bldgNo02 = item.bldgBonbHo // ''
          this.coAddr = this.tmpCustCardVO.jobpAddr
          this.coDtlad = this.tmpCustCardVO.jobpDtlad + this.tmpCustCardVO.bldgNo02
        }

        this.tmpCustCardVO.jobpFrnoPstcd = item.pstCd // 우편번호 '06978'
        this.tmpCustCardVO.jobpAddrRefnCd = item.addrRefnCd // '01' // 직장주소정제코드(01: 정제주소, 02:입력주소)
        this.tmpCustCardVO.jobpAddrStatCd = '11' // 직장주소상태코드 (11:정상, 12:오류, 13:반송(생명특화))
        this.isHideCoDtladYn = false
        
        // 아코디언 펼침 이슈로 닫열 추가
        this.$refs.expItem2.collapse()
        this.$nextTick(() => {
          this.$refs.expItem2.expand()
        })
      }

      // 기존 고객의 변경인 경우 true로 처리
      this.tmpCustCardVO.isAddrChanged = false
      if(this.tmpCustCardVO.isExistCustYN === 'Y'){
        this.tmpCustCardVO.isAddrChanged = true
      }
      
    },
    /******************************************************************************
    * Function명 : fn_Click
    * 설명       : row 선택 시 호출. 선택된 row의 radio value를 변경
    ******************************************************************************/
    fn_CheckSelectedItem (val) {
      this.addrType = val
    },
    /******************************************************************************
    * Function명 : fn_CloseBackCnfrmPop
    * 설명       : OPEN(컨펌팝업열기), Cancel(화면유지), Close(동의종료) 선택에 대한 화면 이동
    ******************************************************************************/
    fn_CloseBackCnfrmPop (param) {
     
      switch(param){
        case 'Cancel':
          this.$emit('onPopupCancel')
        break
        case 'Confirm':
          let lv_Params = {
            resCustCardVO: JSON.parse(JSON.stringify(this.tmpCustCardVO)),
            addrScCd: this.addrScCd,
            addrType: this.addrType
          }
          if(this.fn_ValidationChecked())
          {

            this.$emit('onPopupClose', lv_Params)
          }
        break
      }
    },
    /******************************************************************************
    * Function명 : fn_ValidationChecked
    * 설명       : 집, 직장 선택 유무 및 값이 있는지 check
    ******************************************************************************/
    fn_ValidationChecked () {
      let lv_RetVal = true

      if(this.addrType === '' ||typeof this.addrType === 'undefined'){
        let lv_Msg = this.$t('cm')['ECMC016'].replace('{0}', '주소선택') // {0}은(는) 필수 입력값입니다.
        this.getStore('confirm').dispatch('ADD', lv_Msg)
        lv_RetVal = false
      }

      if(this.addrType === '11'){
        if(this.homeAddr === '' ||typeof this.homeAddr === 'undefined'){
          let lv_Msg = this.$t('cm')['ECMC016'].replace('{0}', '집주소') // {0}은(는) 필수 입력값입니다.
          this.getStore('confirm').dispatch('ADD', lv_Msg)
          lv_RetVal = false
        }else if(this.homeDtlad === '' ||typeof this.homeDtlad === 'undefined'){
          let lv_Msg = this.$t('cm')['ECMC016'].replace('{0}', '집주소 상세') // {0}은(는) 필수 입력값입니다.
          this.getStore('confirm').dispatch('ADD', lv_Msg)
          lv_RetVal = false
        }
      }else{
        if(this.coAddr === '' ||typeof this.coAddr === 'undefined'){
          let lv_Msg = this.$t('cm')['ECMC016'].replace('{0}', '회사주소') // {0}은(는) 필수 입력값입니다.
          this.getStore('confirm').dispatch('ADD', lv_Msg)
          lv_RetVal = false
        }else if(this.coDtlad === '' ||typeof this.coDtlad === 'undefined'){
          let lv_Msg = this.$t('cm')['ECMC016'].replace('{0}', '회사주소 상세') // {0}은(는) 필수 입력값입니다.
          this.getStore('confirm').dispatch('ADD', lv_Msg)
          lv_RetVal = false
        }
      }
      return lv_RetVal
    },
    /******************************************************************************
    * Function명 : fn_ClearAddr
    * 설명       : 집, 직장 입력값 초기화
    ******************************************************************************/
    fn_ClearAddr (addrType) {
      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          content: '선택한 주소를 삭제할까요?'
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(alertObj)

            if (addrType === '11') { // 자택
              this.homeAddr = ''
              this.homeDtlad = ''
            } else if (addrType === '12') { // 직장
              this.coAddr = ''
              this.coDtlad = ''
            }
          },
          onPopupClose: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      });
      
    }

  }//methods end
}
</script>
